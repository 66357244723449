import { useState, useEffect, createRef, useRef } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import classNames from 'classnames'
import { shuffleArray } from 'helpers'

export default function Video({ videos }) {
  let videoContainerRef = createRef()
  const [width, setWidth] = useState(2560)
  const [height, setHeight] = useState(1440)

  const videoRefs = useRef([])
  const [shuffledVideos, setShuffledVideos] = useState([])
  const [currentVideo, setCurrentVideo] = useState(0)

  useEffect(() => {
    // Randomize the order of videos on the client side only, once on component mount
    const shuffled = shuffleArray(videos)
    setShuffledVideos(shuffled)
  }, []) // Run only once on component mount

  useEffect(() => {
    videoRefs.current = Array(shuffledVideos.length)
      .fill()
      .map((_, i) => videoRefs.current[i] || null)
  }, [shuffledVideos])

  useEffect(() => {
    const currentVideoRef = videoRefs.current[currentVideo]
    const nextVideoIndex = (currentVideo + 1) % shuffledVideos.length
    const nextVideoRef = videoRefs.current[nextVideoIndex]

    if (currentVideoRef) {
      currentVideoRef.currentTime = 0
      currentVideoRef.play().catch((error) => {
        console.error('Error playing video:', error)
        currentVideoRef.muted = true
        currentVideoRef.play()
      })

      const handleTimeUpdate = () => {
        if (
          currentVideoRef.duration - currentVideoRef.currentTime <= 0.5 &&
          nextVideoRef
        ) {
          nextVideoRef.play().catch((error) => {
            console.error('Error playing next video:', error)
            nextVideoRef.muted = true
            nextVideoRef.play()
          })
        }
      }

      const handleEnded = () => {
        setCurrentVideo(nextVideoIndex)
      }

      currentVideoRef.addEventListener('timeupdate', handleTimeUpdate)
      currentVideoRef.addEventListener('ended', handleEnded)
      return () => {
        currentVideoRef.removeEventListener('timeupdate', handleTimeUpdate)
        currentVideoRef.removeEventListener('ended', handleEnded)
      }
    }
  }, [currentVideo, shuffledVideos])

  useEffect(() => {
    resize()
    window.addEventListener('resize', resize.bind())
    return () => {
      window.removeEventListener('resize', resize.bind())
    }
  })

  const resize = (e) => {
    const ratio = 2560 / 1440,
      ww = window.innerWidth,
      wh = window.innerHeight

    if (ww / wh < ratio) {
      setWidth(ww)
      setHeight(ww / ratio)
    } else {
      setWidth(wh * ratio)
      setHeight(wh)
    }
  }

  return (
    <div className="video-container d-none d-md-block" ref={videoContainerRef}>
      <div className="content">
        <div className="layer">
          <div
            className="aspect"
            style={{ width: width, height: height, padding: 0 }}
          >
            {shuffledVideos.map((video, index) => (
              <div key={`div_${index}`}>
                <video
                  key={`video_${index}`}
                  ref={(el) => (videoRefs.current[index] = el)}
                  className={classNames('video', {
                    active: currentVideo === index,
                  })}
                  autoPlay={currentVideo === index}
                  playsInline
                  preload="auto"
                  muted
                >
                  <source
                    src={video.h264}
                    type="video/mp4; codecs=avc1.4D401E,mp4a.40.2"
                  />
                  <source
                    src={video.av1}
                    type="video/mp4; codecs=av01.0.05M.08,opus"
                  />
                  <source
                    src={video.hevc}
                    type="video/mp4; codecs=hevc,mp4a.40.2"
                  />
                </video>
                <div
                  key={`line_${index}`}
                  className={classNames('line', {
                    active: currentVideo === index,
                  })}
                >
                  {video.logo && (
                    <>
                      <Link
                        key={`link_${index}`}
                        passHref
                        href={video.link}
                        target="_blank"
                      >
                        <Image
                          key={`image_${index}`}
                          src={video.logo}
                          alt={video.client}
                          width={video.imgWidth}
                          height={video.imgHeight}
                        />
                      </Link>
                      <span key={`span_${index}`} className="d-block">
                        {video.text}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="layer">
          <div
            className="aspect"
            style={{ width: width, height: height, padding: 0 }}
          >
            <div className="vignette"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
